import * as React from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Chip,
  Divider,
  Fab,
  FormControl,
  Grid,
  Input,
  InputLabel,
  Modal,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DataTable from "react-data-table-component";
import { useState } from "react";
import Loader from "ui-component/Loader";
import { useNavigate, useParams } from "react-router";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useEffect } from "react";
import moment from "moment";
import {
  createUpdateRSSFeedByAdmin,
  deleteRSSFeedByAdmin,
  fetchOrganizationNewswirePosts,
  fetchOrganizationPlans,
  organizationNewswireRssFeeds,
} from "api/api";
import Avatar from "@mui/material/Avatar";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import { AlertDialog } from "utils/common";

export default function NewswireRssFeedPage() {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [pending, setPending] = React.useState(false);
  const theme = useTheme();

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [metaData, setMetaData] = useState({});

  const [rssModalData, setRssModalData] = useState({});
  const [feedForm, setFeedForm] = useState({ name: "", url: "", id: null });

  const [selectedRows, setSelectedRows] = useState([]);

  // Media query to check screen size, e.g., smaller screens (600px and below)
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const navigate = useNavigate();
  const params = useParams();

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state?.selectedRows.map((r) => r.id));
  }, []);

  const handleDateTime = (value) => {
    const date = moment.utc(value); // Parse the input as a UTC date
    const formattedDate = date.local().format("DD-MMM-YY hh:mm Z"); // Convert to local timezone and format
    return formattedDate;
  };

  const HandleApplyFilter = async () => {
    setPending(true);
    setLoader(true);
    await organizationNewswireRssFeeds(page, perPage, params.organizationId)
      .then((res) => {
        setData(res.data.data);
        setTotalRows(res.data.totalCount);
        setMetaData(res.data.metaData);
      })
      .catch((err) => {});
    setPending(false);
    setLoader(false);
  };

  const handleRSSFeedEditClick = (data) => {
    handleOpen();
    setRssModalData({
      title: `Update rss feed`,
    });
    setFeedForm({ name: data.feedName, url: data.url, id: data.id });
  };

  const handleRSSFeedAddNewButtonClick = () => {
    handleOpen();
    setRssModalData({
      title: `Create new rss feed`,
    });
  };

  useEffect(() => {
    setColumns([
      {
        name: "Created At",
        selector: (row, index) => handleDateTime(row.createdAt),
        sortable: false,
        maxWidth: "200px",
      },
      {
        name: "Rss Feed Name",
        selector: (row, index) => row.feedName,
        sortable: false,
        maxWidth: "200px",
      },
      {
        name: "Url",
        selector: (row) => row.url,
        sortable: true,
        maxWidth: "600px",
      },
      {
        name: "User",
        selector: (row) => row.userName,
        sortable: true,
        maxWidth: "600px",
      },
      {
        name: "Feed Followed by",
        selector: (row) => row.rssFeedFollowCounter,
        sortable: true,
        maxWidth: "600px",
      },
      {
        name: "Edit RSS Feed",
        selector: (row) => (
          <>
            <Button
              onClick={() => {
                handleRSSFeedEditClick(row);
              }}
              variant="outlined"
            >
              Edit
            </Button>
          </>
        ),
        sortable: true,
        maxWidth: "600px",
      },
      {
        name: "Delete RSS Feed",
        selector: (row) => (
          <>
            <Button
              onClick={() => {
                handleClickAlertDialogOpen(row);
              }}
              variant="outlined"
              color="error"
            >
              Delete
            </Button>
          </>
        ),
        sortable: true,
        maxWidth: "600px",
      },
    ]);
  }, []);

  useEffect(() => {
    HandleApplyFilter();
  }, [page, perPage]);

  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const [alertDialogopen, setAlertDialogopen] = React.useState(false);
  const [alertDialogData, setAlertDialogData] = React.useState({});

  const handleClickAlertDialogOpen = (data) => {
    setAlertDialogopen(true);
    setAlertDialogData(data);
  };

  const handleCloseAlertDialog = ()=>{
    setAlertDialogopen(false);
    setAlertDialogData({})
  }



  const handleActionsOnAlertDialog = async (data,action) => {

    switch(action){

      case 'Disagree': 
      
      handleCloseAlertDialog();
      break;
      case 'Agree': 
        
      try {
        const response = await deleteRSSFeedByAdmin(data?.id);
        if(response.data.status==='success'){
          toast.success("Success", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          HandleApplyFilter();
          handleCloseAlertDialog()
        }
      } catch (error) {
        const errorMessage = error?.response?.data?.message;
        alert(errorMessage)
      }
        
      break;
      
      default:
        alert('Action not defined!')
      }
  };

  const handleOpen = (row) => {
    setOpen(true);
  };

  const handleClose = () => {
    setFeedForm({ name: "", url: "" });
    setOpen(false);
    HandleApplyFilter();
  };

  const handleModalSubmitButton = async () => {
    if (!(feedForm.name.length > 0 || feedForm.url.length > 0)) {
      alert("Enter data!");
    }

    const data = {
      id: feedForm.id || null,
      name: feedForm.name,
      url: feedForm.url,
      organizationId: metaData.organizationId,
    };

    try {
      const response = await createUpdateRSSFeedByAdmin({ ...data });
      if (response.data?.status === "success") {
        handleClose();
        toast.success("Success", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.log({ error });
      const errorMessage = error.response?.data?.message || "Error!";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  // Modal Styles
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallScreen
      ? "90%"
      : isMediumScreen
      ? "70%"
      : isLargeScreen
      ? "60%"
      : "40%",
    maxHeight: "70vh", // Prevent overflow on small screens
    minHeight: "70vh",
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 24,
    overflowY: "auto", // Add scrolling for overflow content
    p: 4,
  };

  return (
    <>
      {loader === true && <Loader />}

      <DataTable
        title={
          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                onClick={() => {
                  navigate(-1);
                }}
              >
                {" "}
                <KeyboardBackspaceIcon /> Back{" "}
              </Button>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={1}
              >
                <p>
                  RSS Feed - {metaData.organizationName} ({totalRows})
                </p>
                <Box>
                  <Avatar src={metaData.orgProfileImage}></Avatar>
                </Box>
              </Box>
            </div>
          </>
        }
        columns={columns}
        data={data}
        progressPending={pending}
        pagination
        subHeader
        // subHeaderComponent={subHeaderComponentMemo}
        persistTableHead
        fixedHeader
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        highlightOnHover
        // selectableRows
        // contextActions={contextActions}
        onSelectedRowsChange={handleRowSelected}
        // clearSelectedRows={toggleCleared}
        // row exapndable
        expandableRows={false}
        // expandableRowsComponent={ExpandedComponent}
        expandOnRowClicked={false}
        expandOnRowDoubleClicked={false}
        expandableRowsHideExpander={false}
      />

      {/* Modal for displaying selected row details */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          {rssModalData ? (
            <>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Box>
                  <p style={{ fontSize: "22px" }}>{rssModalData.title}</p>
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  gap={1}
                  alignItems={"center"}
                >
                  <p style={{ fontSize: "22px" }}>
                    {metaData.organizationName}
                  </p>
                  <Avatar src={metaData.orgProfileImage}></Avatar>
                </Box>
              </Box>

              <Box
                component="form"
                sx={{ "& > :not(style)": { m: 1, width: "100%" } }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="outlined-basic"
                  label="Feed name"
                  variant="outlined"
                  required
                  value={feedForm.name}
                  onChange={(e) => {
                    setFeedForm((pre) => {
                      return { ...pre, name: e.target.value };
                    });
                  }}
                />
                <TextField
                  id="outlined-basic"
                  label="Feed url"
                  variant="outlined"
                  value={feedForm.url}
                  required
                  onChange={(e) => {
                    setFeedForm((pre) => {
                      return { ...pre, url: e.target.value };
                    });
                  }}
                />
              </Box>
              <Box display={"flex"} justifyContent={"center"} mt={2}>
                <Button
                  disabled={
                    feedForm.name.length > 0 && feedForm.url.length > 0
                      ? false
                      : true
                  }
                  type="submit"
                  onClick={() => {
                    handleModalSubmitButton();
                  }}
                  variant="outlined"
                >
                  Submit
                </Button>
              </Box>
            </>
          ) : (
            <></>
          )}
        </Box>
      </Modal>

      <Fab
        onClick={() => {
          handleRSSFeedAddNewButtonClick();
        }}
        sx={{
          position: "absolute",
          bottom: "2%",
          right: "3%",
        }}
        color="primary"
        aria-label="add"
      >
        <AddIcon />
      </Fab>

      <AlertDialog
        title={"Feed Delete Confirmation"}
        content={"Do you really want to delete this RSS feed? Please confirm your action."}
        onClose={handleCloseAlertDialog}
        open={alertDialogopen}
        actions={[
          { label: "No", onClick: handleActionsOnAlertDialog, action:'Disagree' },
          {
            label: "Yes",
            onClick: handleActionsOnAlertDialog,
            autoFocus: true,
            action:'Agree'
          },
        ]}
        data={alertDialogData}
      />
    </>
  );
}
