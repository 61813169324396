import { borderRadius } from "@mui/system";
import axios from "axios";
import moment from "moment";
import validator from "validator";
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


export const handleDateTime = (value) => {
    const date = moment.utc(value); // Parse the input as a UTC date
    const formattedDate = date.local().format("DD-MMM-YY hh:mm Z"); // Convert to local timezone and format
    return formattedDate;
};

export const formatBytes = (bytes) => {
    if (bytes === 0) return '0 B';
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${parseFloat((bytes / Math.pow(1024, i)).toFixed(2))} ${sizes[i]}`;
};



export function AlertDialog({ 
    open, 
    onClose, 
    title, 
    content, 
    actions,
    data
  }) {
    return (
      <Dialog
      sx={{
        '& .MuiDialog-paper': {
          width: '400px !important', // Set width
          height: '200px', // Set height
          maxWidth: 'none', // Prevent max-width overriding the width
        },
      }}
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={{ cursor: 'move' }} fontSize={'22px'} id="draggable-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText fontSize={'18px'}>
           {content}
          </DialogContentText>
          </DialogContent>
        <DialogActions>
          {actions?.map((action, index) => (
            <Button key={index} onClick={()=>{action.onClick(data,action.action)}} autoFocus={action.autoFocus}>
              {action.label}
            </Button>
          ))}
        </DialogActions>
      </Dialog>
    );
  }
