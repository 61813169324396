import * as React from 'react';
import Box from '@mui/material/Box';
import { Button, Chip, Divider,  Grid,  Modal, Stack, Typography, useMediaQuery,useTheme } from '@mui/material';
import DataTable from 'react-data-table-component';
import { useState } from 'react';
import Loader from 'ui-component/Loader';
import { useNavigate, useParams } from 'react-router';
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useEffect } from 'react';
import moment from 'moment';
import { fetchOrganizationNewswirePosts, fetchOrganizationPlans } from 'api/api';
import Avatar from '@mui/material/Avatar';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';


export default function NewswirePostMainPage() {
  const theme = useTheme();

  // Media query to check screen size, e.g., smaller screens (600px and below)
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down('lg'));


  const [loader,setLoader] = useState(false)
  const [data,setData] = useState([])
  const [columns,setColumns] = useState([])
  const [pending, setPending] = React.useState(false);

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [metaData, setMetaData] = useState({});

  const [selectedRows, setSelectedRows] = useState([]);


  const navigate = useNavigate()
  const params = useParams()

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state?.selectedRows.map((r) => r.id));
  }, []);

  const handleDateTime = (value) => {
    const date = moment.utc(value); // Parse the input as a UTC date
    const formattedDate = date.local().format("DD-MMM-YY hh:mm Z"); // Convert to local timezone and format
    return formattedDate;
};

    const HandleApplyFilter = async () => {
      setPending(true);
      setLoader(true);
      await fetchOrganizationNewswirePosts(page, perPage,params.organizationId)
        .then((res) => {
          setData(res.data.data);
          setTotalRows(res.data.totalCount);
          setMetaData(res.data.metaData)
        })
        .catch((err) => {});
      setPending(false);
      setLoader(false);
    };

  useEffect(() => {
    setColumns([
      {
        name: "Post Type",
        selector: (row,index) => row.postType,
        sortable: false,
        maxWidth: "200px",
      },
      {
        name: "Visibility",
        selector: (row) => row.visibility,
        sortable: true,
        maxWidth: "600px",
      },
      {
        name: "Title",
        selector: (row) => row.title,
        sortable: true,
        maxWidth: "600px",
      },
      {
        name: "Post Pin",
        selector: (row) => row.pinCount,
        sortable: true,
        maxWidth: "600px",
      },
     
      {
        name: "Created At",
        selector: (row) => handleDateTime(row.createdAt),
        sortable: true,
        maxWidth: "600px",
      },
      {
        name: "Updated At",
        selector: (row) => handleDateTime(row.updatedAt),
        sortable: true,
        maxWidth: "600px",
      },
      {
        name: "View Details",
        selector: (row) => <Button variant='outlined' onClick={() => handleOpen(row)} >Details</Button>,
        sortable: true,
        maxWidth: "600px",
      },
    ]);
  }, []);
  

  useEffect(() => {
    HandleApplyFilter();
  }, [page, perPage]);

  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleOpen = row => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  // Modal Styles
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isSmallScreen ? '90%' : isMediumScreen ? '80%': isLargeScreen ? '70%':'70%',
    maxHeight: '80vh', // Prevent overflow on small screens
    minHeight:'80vh',
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 24,
    overflowY: 'auto', // Add scrolling for overflow content
    p: 4,

  };

  return (
    <>
      {loader === true && <Loader />}

      <DataTable
        title={<div>
           <Button onClick={()=>{navigate(-1)}} > <KeyboardBackspaceIcon/> Back </Button>
          <p>Newswire Posts of {metaData.organizationName} ({totalRows})</p>
        </div>}
        columns={columns}
        data={data}
        progressPending={pending}
        pagination
        subHeader
        // subHeaderComponent={subHeaderComponentMemo}
        persistTableHead
        fixedHeader
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        highlightOnHover
        // selectableRows
        // contextActions={contextActions}
        onSelectedRowsChange={handleRowSelected}
        // clearSelectedRows={toggleCleared}
        // row exapndable
        expandableRows={false}
        // expandableRowsComponent={ExpandedComponent}
        expandOnRowClicked={false}
        expandOnRowDoubleClicked={false}
        expandableRowsHideExpander={false}
      />



      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          
        <Box sx={{display:'flex',justifyContent:'space-between'}}>
          <Stack direction="row" alignItems="center" spacing={2} mb={2}>
              <Avatar
                src={selectedRow?.images}
                alt={`image`}
                sx={{ width: 100, height: 100 }}
              />
              <Typography variant="h3">
              {selectedRow?.title}
              </Typography>
            </Stack>
            {/* <Stack direction="row" alignItems="center" spacing={2} mb={2}>
              <Avatar
                src={selectedRow?.images}
                alt={`image`}
                sx={{ width: 60, height: 60 }}
              />
              <Typography variant="h3">
                {'hello'}
              </Typography>
            </Stack> */}
          </Box>
          <Divider textAlign="left"  sx={{ mb: 2 }} >General Post Detail</Divider>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} >
              <Typography variant="subtitle1" color="text.secondary">
                Post Type:
              </Typography>
              <Typography sx={{paddingLeft:'4px'}}>{selectedRow?.postType}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} >
              <Typography variant="subtitle1" color="text.secondary">
                Post visibility:
              </Typography>
              <Typography sx={{paddingLeft:'4px'}}>{selectedRow?.visibility}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} >
              <Typography variant="subtitle1" color="text.secondary">
                Pinned By:
              </Typography>
              <Typography sx={{paddingLeft:'4px'}}>  {selectedRow?.pinCount}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} >
              <Typography variant="subtitle1" color="text.secondary">
                Created At:
              </Typography>
              <Typography sx={{paddingLeft:'4px'}}>   {handleDateTime(selectedRow?.createdAt)}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} >
              <Typography variant="subtitle1" color="text.secondary">
                Updated At:
              </Typography>
              <Typography sx={{paddingLeft:'4px'}}>   {handleDateTime(selectedRow?.updatedAt)}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} >
              <Typography variant="subtitle1" color="text.secondary">
                Created by:
              </Typography>
              <Box sx={{display:'flex',justifyContent:'flex-start',alignItems:'center',gap:'10px'}}>
                <Typography sx={{paddingLeft:'4px'}}> {selectedRow?.userName}  
                </Typography>
                <Avatar alt="Remy Sharp" src={selectedRow?.userProfileImage} /> 
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} >
              <Typography variant="subtitle1" color="text.secondary">
                Description:
              </Typography>
              <Typography sx={{paddingLeft:'4px'}}>   {selectedRow?.description}</Typography>
            </Grid>
            
            <Grid item xs={12} sm={12} md={12} >
            <Divider textAlign="left"  sx={{ mb: 2 }} >Post Categories</Divider>
            <Typography variant="subtitle1" color="text.secondary">
                Categories:
              </Typography>
              <Stack direction={'row'} spacing={1} sx={{ overflowX: 'auto' }}>
                    {selectedRow?.postCategoryList?.map((e, index) => (
                      <Chip key={index} label={e} color="primary" />
                    ))}
                  </Stack>
            </Grid>
           
           
          </Grid>
        
        </Box>
      </Modal>  

    </>
  );
}
