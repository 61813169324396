import axios from "axios";
import { BASE_URL } from "utils/constant";

const url = BASE_URL;

// const url = process.env.REACT_APP_BASE_URL;

const image_url = "https://cdn-icons-png.flaticon.com/512/7088/7088436.png";

export function getToken() {
  return localStorage.getItem("auth-token");
}

export function setToken(data) {
  return localStorage.setItem("auth-token", data);
}

export async function login(username, password) {
  const aUrl = url + "auth/login";
  return await axios.post(aUrl, { username, password });
}

export async function userListing(page, pageSize, orgRole, searchText) {
  const pages = page === 1 ? 0 : page - 1;
  const aUrl =
    url +
    `system-admin/fetchOrganizationsList?page=${pages}&pageSize=${pageSize}&search=${searchText}&role=${orgRole}`;

  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function userSearch(
  searchText,
  page,
  pageSize,
  client,
  vendor,
  consultant
) {
  const clientValue = client === true ? 1 : 0;
  const vendorValue = vendor === true ? 1 : 0;
  const consultantValue = consultant === true ? 1 : 0;
  const aUrl =
    url +
    `admin/user-table-filter?search_text=${searchText}&page=${page}&pageSize=${pageSize}&client=${clientValue}&vendor=${vendorValue}&consultant=${consultantValue}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios(config);
}
export async function userSetting(userid) {
  const aUrl =
    url + `system-admin/organizationSettingData?organizationId=${userid}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios(config);
}

export async function resetPassword(userid, newPassword) {
  const aUrl =
    url +
    `admin/user-password-reset?user_id=${userid}&new_password=${newPassword}`;
  const config = {
    method: "put",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios(config);
}
export async function user_status_change(userid, status) {
  const user_status = status === true ? 1 : 0;
  const aUrl =
    url + `admin/change-user-status?user_id=${userid}&active=${user_status}`;
  const config = {
    method: "put",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios(config);
}
export async function organization_detail(orgId) {
  const aUrl = url + `admin/get-organization-details?organizationId=${orgId}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios(config);
}
export async function get_user_change_data(userid) {
  const aUrl =
    url +
    `system-admin/getOrganizationCategoryEditData?organizationId=${userid}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios(config);
}
export async function get_featur_vendor_data(organizationid) {
  const aUrl =
    url +
    `featured-organization/fetchEditDataForFeaturedOrganization?organizationId=${organizationid}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios(config);
}
export async function fetchCategoryDetails(categoryId) {
  const aUrl =
    url + `system-admin/fetchCategoryDetails?categoryId=${categoryId}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios(config);
}
export async function update_featur_vendor_date(
  organizationid,
  startData,
  endDate,
  type
) {
  const aUrl = url + `featured-organization/upsert`;
  const config = {
    method: "post",
    url: aUrl,
    data: {
      startDate: startData,
      endDate: endDate,
      organizationId: organizationid,
      type: type,
    },
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios(config);
}
export async function update_user_data(
  userId,
  firstName,
  lastName,
  organizationName,
  numberOfEmployee,
  segmentedId,
  country_code,
  mobile_no,
  years_of_business
) {
  const employee = parseInt(numberOfEmployee);

  const aUrl = url + `admin/update-user-details`;
  const config = {
    method: "put",
    url: aUrl,
    data: {
      userId: userId,
      firstName: firstName,
      lastName: lastName,
      organizationName: organizationName,
      numberOfEmployee: employee,
      marketSegmentId: segmentedId,
      countryCode: country_code,
      mobileNumber: mobile_no,
      yearsInBusiness: parseInt(years_of_business),
    },
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios(config);
}

export async function get_featur_vendor_list(pages, pageSize, searchText) {
  const page = pages - 1;
  const aUrl =
    url +
    `featured-organization/fetchFeaturedOrganization?page=${page}&pageSize=${pageSize}&searchText=${searchText}`;

  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios(config);
}
export async function get_category_list(page, pageSize) {
  const aUrl =
    url + `system-admin/fetchCategory?page=${page}&pageSize=${pageSize}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios(config);
}
export async function category_update(
  categoryId,
  name,
  icon,
  status,
  shortname,
  priority,
  code
) {
  const formData = new FormData();
  formData.append("image", icon);
  formData.append("name", name);
  formData.append("active", status === true ? "yes" : "no");
  formData.append("shortName", shortname);
  formData.append("priority", priority);
  formData.append("id", categoryId);
  formData.append("code", code);

  const aUrl = url + `system-admin/updateCategory`;
  const config = {
    method: "put",
    url: aUrl,
    data: formData,

    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios(config);
}
export async function category_user_created(name, shortname, categoryCode) {
  const aUrl = url + `category/createCategory`;
  const config = {
    method: "post",
    url: aUrl,
    data: {
      name: name,
      icon: image_url,
      shortName: shortname,
      categoryCode: categoryCode,
    },
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios(config);
}
export async function check_connection(idOne, idTwo) {
  const aUrl =
    url +
    `connection/getConnectionStatus?organizationId1=${idOne}&organizationId2=${idTwo}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios(config);
}
export async function update_connection(IdOne, IdTwo) {
  const aUrl =
    url +
    `connection/connectOrganization?organizationId1=${IdOne}&organizationId2=${IdTwo}`;
  const config = {
    method: "put",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios(config);
}
export async function disconnect_connection(IdOne, IdTwo) {
  const aUrl =
    url +
    `connection/disconnectOrganization?organizationId1=${IdOne}&organizationId2=${IdTwo}`;
  const config = {
    method: "put",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios(config);
}
export async function export_data(
  client,
  vendor,
  school,
  college_arg,
  team_arg,
  venue,
  consultant,
  integrator,
  manufacturer,
  reseller
) {
  const Client = client === true ? 1 : 0;
  const Vendor = vendor === true ? 1 : 0;
  const High_School = school === true ? 1 : 0;
  const College = college_arg === true ? 1 : 0;
  const Team = team_arg === true ? 1 : 0;
  const Venue = venue === true ? 1 : 0;
  const Consultant = consultant === true ? 1 : 0;
  const Integrator = integrator === true ? 1 : 0;
  const Manufacturer = manufacturer === true ? 1 : 0;
  const Reseller = reseller === true ? 1 : 0;

  const aUrl =
    url +
    `export/user?client=${Client}&vendor=${Vendor}&High_School=${High_School}&College=${College}&Team=${Team}&Venue=${Venue}&Consultant=${Consultant}&Integrator=${Integrator}&Manufacturer=${Manufacturer}&Reseller=${Reseller}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios(config);
}

export async function get_user_email() {
  const aUrl = url + `admin/organizationEmailList`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios(config);
}

export async function get_connection(organizationId, pages, pageSize) {
  const page = pages - 1;
  const aUrl =
    url +
    `system-admin/fetchOrganizationConnection?organizationId=${organizationId}&page=${page}&pageSize=${pageSize}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function get_orgUsers(organizationId, pages, pageSize) {
  const page = pages - 1;
  const aUrl =
    url +
    `system-admin/fetchUsersOfOrganization?organizationId=${organizationId}&page=${page}&pageSize=${pageSize}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function get_dashboard_data() {
  const aUrl = url + `system-admin/dashboard`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function get_reported_message_data(pages, pageSize) {
  const page = pages - 1;
  const aUrl =
    url + `message/getReportedMessageDetails?page=${page}&pageSize=${pageSize}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function search_organization_data(value) {
  const aUrl = url + `admin/searchOrganization?searchText=${value}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function update_avatar_user_setting_page(avatar, id) {
  const data = new FormData();
  data.append("file", avatar);

  const aUrl =
    url + `system-admin/updateOrganizationProfileImage?organizationId=${id}`;
  const config = {
    method: "post",
    url: aUrl,
    data: data,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function market_segment() {
  const aUrl = url + `market-segment/get-all-market-segment`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

export async function get_role() {
  const aUrl = url + `role/get-role`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

//
export async function get_sub_role_one(role) {
  const aUrl = url + `role/get-subrole_first?role=${role}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function get_sub_role_two(role, subRoleFirstValue) {
  const aUrl =
    url +
    `role/get-subrole_second?role=${role}&subroleFirst=${subRoleFirstValue}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

export async function get_sports() {
  const aUrl = url + `sport/get-sport`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function get_product_category() {
  const aUrl = url + `category/get-all-category`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
//

export async function create_user(
  user_data,
  selectRole,
  sub_role_first,
  sub_role_second,
  sportData,
  productCategory,
  market_segment_value
) {
  const aUrl = url + `admin/registerNewOrganization`;
  const config = {
    method: "post",
    url: aUrl,
    data: {
      firstName: user_data.firstName,
      lastName: user_data.lastName,
      mobileNumber: user_data.mobileNo,
      email: user_data.email,
      password: user_data.password,
      organizationName: user_data.orgName,
      role: selectRole[0],
      subrole1: sub_role_first ? sub_role_first : null,
      subrole2: sub_role_second ? sub_role_second : null,
      sports: sportData,
      productCategories: productCategory,
      marketSegment: market_segment_value,
    },
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

//  event management

export async function getEventData(page, perPage) {
  const pages = page - 1;
  const aUrl = url + `admin/getEvent?page=${pages}&pageSize=${perPage}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function getEventbyId(eventId) {
  const aUrl = url + `admin/editEventGet?eventId=${eventId}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function getEventViewDataById(eventId) {
  const aUrl = url + `system-admin/fetchEventDetails?eventId=${eventId}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

export async function SearchEvent(searchText) {
  const aUrl =
    url +
    `system-admin/eventFiltrationSearchLookUpApi?searchText=${searchText}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function updateEditEvent(eventData) {
  const aUrl = url + `admin/updateEvent`;
  const config = {
    method: "put",
    url: aUrl,
    data: {
      id: eventData?.id,
      eventMode: eventData?.mode,
      eventPrivacy: eventData?.privacy || "",
      eventName: eventData?.name,
      eventCategory: eventData?.category,
      customEventCategory: eventData?.customeventCategory || "",
      startDateTime: eventData?.start,
      endDateTime: eventData?.end,
      eventContact: eventData?.contact || "",
      contactPhone: eventData?.contact || "",
      eventDescription: eventData?.description,
      eventWebsite: eventData?.website || null,
    },
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function getFilterData(searchData, mode, period, perpage, page) {
  const pages = page - 1;
  const aUrl =
    url +
    `system-admin/getEventsByFiltration?page=${pages}&pageSize=${perpage}&searchText=${
      searchData?.name || null
    }&eventMode=${mode}&period=${period}&searchFlag=${searchData?.flag || 0}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
// ims flow

export async function getImsData(searchData, perpage, page) {
  const pages = page - 1;
  const aUrl =
    url +
    `admin/getIMSLocation?page=${pages}&pageSize=${perpage}&searchText=${
      searchData?.name || null
    }&searchFlag=${searchData?.flag || 0}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function imsLookupData(searchText) {
  const aUrl =
    url + `admin/imsLocationFilterationSearchLookUp?searchText=${searchText}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function imsFilterData(searchData, perpage, page) {
  const pages = page - 1;
  const aUrl =
    url +
    `admin/getIMSLocation?page=${pages}&pageSize=${perpage}&searchText=${
      searchData?.name || null
    }&searchFlag=${searchData?.flag || 0}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function imsViewData(id) {
  const aUrl = url + `admin/imsLocationDetail?imsLocationId=${id}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

export async function getImsbyId(eventId) {
  const aUrl = url + `admin/imsLocationEditGet?imsLocationId=${eventId}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

export async function updateImsFlow(eventData) {
  const aUrl = url + `admin/editImslocation`;
  const config = {
    method: "put",
    url: aUrl,
    data: {
      imsLocationId: eventData?.id,
      locationName: eventData?.name,
      address: eventData?.address,
      doorCode: eventData?.doorCode,
      direction: eventData?.direction,
      phone: eventData?.contact,
    },
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

// product management
export async function getproduct(product, perPage, page) {
  const pages = page - 1;
  const aUrl =
    url +
    `admin/getProductsByFilteration?searchText=${
      product?.name || null
    }&page=${pages}&pageSize=${perPage}&searchFlag=${product?.flag || 0}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function getproductlookupData(searchText) {
  const aUrl =
    url + `admin/productFilterationSearchLookUp?searchText=${searchText}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function getproductViewData(id) {
  const aUrl = url + `admin/productDetails?productId=${id}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function getproductEditData(id) {
  const aUrl = url + `admin/getEditedProductDetail?productId=${id}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function productDelete(id) {
  const aUrl = url + `admin/deleteProduct?productId=${id}`;
  const config = {
    method: "delete",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function productUpdate(Product, orgId, category) {
  const aUrl = url + `system-admin/upsertOrganizationProductCategoryURL`;
  const config = {
    method: "post",
    url: aUrl,
    data: {
      organizationId: orgId,
      categoryId: category,
      url: Product.url,
    },
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function CategoryUrlproductCreate(Product, orgId) {
  console.log("Product", Product);
  const aUrl = url + `system-admin/upsertOrganizationProductCategoryURL`;
  const config = {
    method: "post",
    url: aUrl,
    data: {
      organizationId: orgId,
      categoryId: Product.categoryId,
      url: Product.url,
    },
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

// ims product
export async function getIMSproduct(product, page, perPage) {
  const pages = page - 1;
  const aUrl =
    url +
    `admin/imsGetProductsByFilteration?page=${pages}&pageSize=${perPage}&searchText=${
      product?.name || null
    }&searchFlag=${product?.flag || 0}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function getIMSproductlookupData(searchText) {
  const aUrl =
    url + `admin/imsProductFilterationSearchLookUp?searchText=${searchText}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function getIMSproductViewData(id) {
  const aUrl = url + `admin/imsProductView?imsProductId=${id}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function getIMSproductEditData(id) {
  const aUrl = url + `admin/getImsProductUpdated?imsProductId=${id}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function ImsProductUpdate(updateData) {
  console.table([updateData?.id]);
  const aUrl = url + `admin/updateImsProduct`;
  const config = {
    method: "put",
    url: aUrl,
    data: {
      imsProductId: updateData?.id,
      manufactureName: updateData?.manufacturerName,
      modelNumber: updateData?.modelNumber,
      productName: updateData?.productName,
      serialNumber: updateData?.serialNumber,
    },
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

// unverified producer

export async function getUnverifiedProducer(searchText, page, perPage) {
  const pages = page - 1;
  const aUrl =
    url +
    `system-admin/fetchUnverifiedProducerOrganizations?page=${pages}&pageSize=${perPage}&search=${
      searchText || ""
    }`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

export async function getUnverifiedProducerViewData(id) {
  const aUrl =
    url + `system-admin/viewOrganizationDetails?organizationId=${id}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function VerifyProducerByID(id) {
  const aUrl =
    url +
    `system-admin/updateVerifiedFlagOfProducerOrganization?organizationId=${id}`;
  const config = {
    method: "put",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function deleteVendor(id) {
  const aUrl =
    url +
    `featured-organization/deleteFeaturedOrganization?organizationId=${id}`;
  const config = {
    method: "put",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

export async function createMultipleOrganizationWithBlankUser(data) {
  const aUrl = url + `organization/createMultipleOrganizationWithBlankUser`;
  const config = {
    method: "post",
    url: aUrl,
    data,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

// import organization list

export async function UnSettledOrganizationList(
  searcValueByuser,
  pages,
  perPage
) {
  const page = pages;
  const pageSize = perPage;
  const searchText = searcValueByuser;
  const aUrl =
    url +
    `system-admin/fetchCreatedBySystemAdminOrganizations?page=${page}&pageSize=${pageSize}&searchText=${searchText}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function getCsvCategoryList() {
  const aUrl = url + `category/getAllCategoryForSignUp`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function getRequestList(searcValueByuser, pages, perPage) {
  const page = pages - 1;
  const pageSize = perPage;
  const searchText = searcValueByuser;
  const aUrl =
    url +
    `system-admin/fetchUsersWhoRequestedForOrganizationOwnerShip?page=${page}&pageSize=${pageSize}&search=${searchText}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function RequestAccept(id) {
  const aUrl =
    url + `system-admin/assignOrganizationOwnership?memberUserId=${id}`;
  const config = {
    method: "post",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function EventCreat(data) {
  const aUrl =
    url + `system-admin/assignOrganizationOwnership?memberUserId=${data}`;
  const config = {
    method: "post",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function EventTimeZone() {
  const aUrl = url + `timezone/getTimeZoneList`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function getOrganizationNameBySearch(searchText) {
  const aUrl =
    url +
    `system-admin/organizationLookupApiForEventCreation?searchText=${searchText}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function getContactNameData(searchText, OrgId) {
  const getID = () => {
    if (typeof OrgId === "object") {
      return OrgId?.organizationId;
    } else {
      return OrgId;
    }
  };

  const aUrl =
    url +
    `system-admin/fetchUsersByOrganizationId?organizationId=${getID()}&searchText=${searchText}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function getAllCategory() {
  const aUrl = url + `category/getAllCategoryForSignUp`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

function convertToUTC(dateInput, timezoneOffset) {
  // Create a Date object using the input
  let localDate = new Date(dateInput);

  // Get the UTC offset in milliseconds and adjust it by the provided timezone offset
  let utcOffset = localDate.getTimezoneOffset() * 60000; // Convert minutes to milliseconds
  let targetTimezoneOffset = timezoneOffset * 3600000; // Convert hours to milliseconds

  // Calculate the UTC date and time
  let utcDate = new Date(
    localDate.getTime() + utcOffset + targetTimezoneOffset
  );

  return utcDate.toISOString();
}

export async function createEventByAdmin(
  eventMode,
  eventprivacy,
  eventType,
  eventAddress,
  eventOnlineLink,
  eventName,
  eventCategory,
  eventCategoryOthers,
  startTimeAndDateConverted,
  endTimeAndDateConverted,
  selectedTimezone,
  conatctName,
  eventEmail,
  conatctNumber,
  eventDescription,
  evnetWebsiteLink,
  eventSubline,
  organizationNameSelectByuser,
  image,
  venueMapPDFLink,
  contactValue,
  addressOne,
  addressTwo,
  addressThree,
  postalCode,
  city,
  countrySelectData,
  selectStateByuser,
  platform,
  passcode,
  location,
  customOrg
) {
  console.log("contactValue", contactValue);
  console.log("conatctName", conatctName);

  const aUrl = url + `system-admin/createEvent`;
  const config = {
    method: "post",
    url: aUrl,
    data: {
      eventMode: eventMode,
      eventPrivacy: eventMode === "Public" ? "" : eventprivacy,
      eventType: eventType,
      eventAddress: eventAddress,
      eventOnlineLink: eventType !== "In Person" ? eventOnlineLink : "",
      eventName: eventName,
      eventCategory: eventCategory,
      customEventCategory: eventCategory === "Other" ? eventCategoryOthers : "",
      startDateTime: startTimeAndDateConverted,
      endDateTime: endTimeAndDateConverted,
      eventTimeZoneId: selectedTimezone,
      contactUserId: conatctName?.id ? conatctName.id : "",
      contactName: conatctName?.id
        ? ""
        : conatctName?.fullName
        ? conatctName?.fullName
        : "",
      contactEmail: eventEmail,
      contactPhone: conatctNumber,
      eventDescription: eventDescription,
      eventWebsite: evnetWebsiteLink.length <= 8 ? "" : evnetWebsiteLink,
      eventIconLink: image ? image : "",
      venueMapPDFLink: venueMapPDFLink || null,
      subLine: eventSubline,
      customOrganizationName: organizationNameSelectByuser ? "" : customOrg,
      organizationId: organizationNameSelectByuser
        ? organizationNameSelectByuser
        : "",
      addressLine1: eventType === "In Person" ? addressOne : "",
      addressLine2: eventType === "In Person" ? addressTwo : "",
      postalCode: eventType === "In Person" ? postalCode : "",
      city: eventType === "In Person" ? city : "",
      state: eventType === "In Person" ? selectStateByuser : "",
      country: eventType === "In Person" ? countrySelectData : "",
      platform: eventType !== "In Person" ? platform : "",
      passcode: eventType !== "In Person" ? passcode : "",
      location: eventType === "In Person" ? location : "",
    },
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function updateEventByAdmin(
  eventMode,
  eventprivacy,
  eventType,
  eventAddress,
  eventOnlineLink,
  eventName,
  eventCategory,
  eventCategoryOthers,
  startTimeAndDateConverted,
  endTimeAndDateConverted,
  selectedTimezone,
  conatctName,
  eventEmail,
  conatctNumber,
  eventDescription,
  evnetWebsiteLink,
  eventSubline,
  organizationNameSelectByuser,
  image,
  contactValue,
  addressOne,
  addressTwo,
  addressThree,
  postalCode,
  city,
  countrySelectData,
  selectStateByuser,
  platform,
  passcode,
  location,
  eventId,
  customOrg,
  countryCallingCode,
  venueMapPDFLink
) {
  //   eventMode,
  //   eventprivacy,
  //   eventType,
  //   eventAddress,
  //   eventOnlineLink,
  //   eventName,
  //   eventCategory,
  //   eventCategoryOthers,
  //   startTimeAndDateConverted,
  //   endTimeAndDateConverted,
  //   selectedTimezone,
  //   conatctName,
  //   eventEmail,
  //   conatctNumber,
  //   eventDescription,
  //   evnetWebsiteLink,
  //   eventSubline,
  //   organizationNameSelectByuser,
  //   image,
  //   contactValue,
  //   addressOne,
  //   addressTwo,
  //   addressThree,
  //   postalCode,
  //   city,
  //   countrySelectData,
  //   selectStateByuser,
  //   platform,
  //   passcode,
  //   location,
  //   customOrg,
  //   eventId
  // );
  console.log("eventCategory", eventCategory);
  const aUrl = url + `system-admin/updateEvent`;
  const config = {
    method: "put",
    url: aUrl,
    data: {
      platform: eventType !== "In Person" ? platform : "",
      passcode: eventType !== "In Person" ? passcode : "",
      location: eventType === "In Person" ? location : "",

      eventId: eventId,
      organizationId: organizationNameSelectByuser
        ? organizationNameSelectByuser?.organizationId
        : "",
      customOrganizationName: organizationNameSelectByuser?.organizationId
        ? ""
        : customOrg,
      eventMode: eventMode,
      eventPrivacy: eventMode === "Public" ? "" : eventprivacy,
      eventType: eventType,
      eventOnlineLink: eventType !== "In Person" ? eventOnlineLink : "",
      eventName: eventName,
      eventCategory: eventCategory,
      customEventCategory: eventCategory === "Other" ? eventCategoryOthers : "",

      startDateTime: startTimeAndDateConverted,
      endDateTime: endTimeAndDateConverted,
      eventTimeZoneId: selectedTimezone,
      contactName: conatctName?.id
        ? ""
        : conatctName?.fullName
        ? conatctName?.fullName
        : contactValue,
      contactUserId: conatctName?.id ? conatctName.id : "",
      contactEmail: eventEmail,
      contactPhone: conatctNumber,
      eventDescription: eventDescription,
      eventWebsite: evnetWebsiteLink?.length <= 8 ? "" : evnetWebsiteLink,
      eventIconLink: image ? image : "",
      subLine: eventSubline,
      addressLine1: eventType === "In Person" ? addressOne : "",
      addressLine2: eventType === "In Person" ? addressTwo : "",
      postalCode: eventType === "In Person" ? postalCode : "",
      city: eventType === "In Person" ? city : "",
      state: eventType === "In Person" ? selectStateByuser : "",
      country: eventType === "In Person" ? countrySelectData : "",
      venueMapPDFLink: venueMapPDFLink || null,
    },
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

export async function updateCategoryEdit(data, id) {
  const aUrl =
    url + `system-admin/updateOrganizationCategory?organizationId=${id}`;
  const config = {
    method: "post",
    url: aUrl,
    data: data,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function getCountryData() {
  const aUrl = `https://fir-project-5d3e7.firebaseapp.com/assets/packages/country_state_city/lib/assets/country.json`;
  const config = {
    method: "get",
    url: aUrl,
  };
  return await axios(config);
}
export async function uploadEventImage(file) {
  const prodUrl = "https://api.venuetechconnect.com/api/";
  let data = new FormData();
  data.append("file", file);
  const aUrl = prodUrl + `fileUpload/upload`;
  const config = {
    method: "post",
    data: data,
    url: aUrl,
  };
  return await axios(config);
}

export async function CheckUser(token) {
  const aUrl = url + `user/profile`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios(config);
}
export async function getEventEditData(eventId) {
  const aUrl = url + `system-admin/fetchEditEvent?eventId=${eventId}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios(config);
}

export async function fetchUserDeletionLogs(searchText, status, perPage, page) {
  const pages = page - 1;
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url:
      url +
      `system-admin/userDeletionLogsFiltration?page=${pages}&pageSize=${perPage}&searchText=${searchText}&status=${status}`,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

export async function fetchOrgDeleteLogs(searchText, status, perpage, page) {
  const pages = page - 1;
  const aUrl =
    url +
    `system-admin/organizationDeletionLogsFiltration?page=${pages}&pageSize=${perpage}&searchText=${searchText}&status=${status}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

export async function FetchOrgDetailsList(OrgId) {
  const aUrl = url + `system-admin/organizationDetails?organizationId=${OrgId}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

export async function faqData(searchText, page, pageSize) {
  const pages = page === 1 ? 0 : page - 1;
  const aUrl =
    url +
    `system-admin/fetchFAQ?searchText=${searchText}&page=${pages}&pageSize=${pageSize}`;

  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

// product category url
export async function getProductList(searchText, page, perPage, id) {
  const pages = page - 1;
  const aUrl =
    url +
    `system-admin/fetchOrganizationCategoryURL?searchText=${searchText}&page=${pages}&pageSize=${perPage}&organizationId=${id}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

// delete event
export async function DeleteEvent(id) {
  const aUrl = url + `system-admin/softDeleteEvent?eventId=${id}`;
  const config = {
    method: "delete",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function UndoDeleteEvent(id) {
  const aUrl = url + `system-admin/undoSoftDeleteEvent?eventId=${id}`;
  const config = {
    method: "put",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

// error logs

export async function getErrorList(searchText, pages, perPage) {
  const page = pages - 1;
  const aUrl =
    url +
    `system-admin/fetchErrorLogs?page=${page}&pageSize=${perPage}&searchText=${searchText}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function DeleteErrorLogs(DeleteData) {
  const aUrl = url + `system-admin/deleteLogs`;
  const config = {
    method: "delete",
    url: aUrl,
    data: DeleteData,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

// getProductCategoryUrlData
export async function getProductCategoryUrlData(orgId) {
  const aUrl =
    url +
    `system-admin/fetchOrganizationAssociatedCategories?organizationId=${orgId}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

export async function deleteProductCategory(categoryId, orgId) {
  const aUrl =
    url +
    `system-admin/deleteOrganizationProductCategoryURL?organizationId=${orgId}&categoryId=${categoryId}`;
  const config = {
    method: "delete",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

// faq create, update and delete

export async function faqCreate(question, answer, priority) {
  const aUrl = url + `system-admin/createFAQQuestion`;
  const config = {
    method: "post",
    url: aUrl,
    data: {
      question: question,
      answer: answer,
      priority: priority,
    },
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function faqQuestionUpdate(id, question, answer, priority) {
  const aUrl = url + `system-admin/updateFAQQuestion`;
  const config = {
    method: "put",
    url: aUrl,
    data: {
      id: id,
      question: question,
      answer: answer,
      priority: priority,
    },
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function faqQuestionDelete(id) {
  const aUrl = url + `system-admin/deleteFAQ?faqId=${id}`;
  const config = {
    method: "put",
    url: aUrl,

    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

export async function fetchMessageSettingData() {
  const aUrl = url + `system-admin/getMessageSettingData`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

// import producer product

export async function importProducerProduct(orgId, data) {
  const aUrl = url + `organization/importProducerProduct`;
  const config = {
    method: "post",
    url: aUrl,
    data: {
      organizationId: orgId,
      data: data,
    },
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function getProductCategoryForProducer() {
  const aUrl = url + `category/getAllCategoryForSignUp`;
  const config = {
    method: "get",
    url: aUrl,

    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function fetchProducerProduct(page, pageSize, id) {
  const aUrl =
    url +
    `system-admin/fetchProducerProductData?organizationId=${id}&page=${page}&pageSize=${pageSize}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

export async function updateGlobalMessageInitiateLimitApi(newLimit) {
  const aUrl =
    url + `system-admin/updateGlobalMessageInitiateLimit?newLimit=${newLimit}`;
  const config = {
    method: "put",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

// get all producer product data
export async function fetchProductAllDataForSkipDuplicate(orgId) {
  const aUrl =
    url +
    `system-admin/fetchProducerProductDataForSkipDuplicate?organizationId=${orgId}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

// import consumer location

export async function importConsumerLocationData(orgId, data) {
  const aUrl = url + `organization/importConsumerLocation`;
  const config = {
    method: "post",
    url: aUrl,
    data: {
      organizationId: orgId,
      data: data,
    },
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

// get all consumer location data
export async function fetchConsumerLocationData(orgId, page, pageSize) {
  const aUrl =
    url +
    `organization/fetchConsumerLocation?organizationId=${orgId}&page=${page}&pageSize=${pageSize}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

// importconsumerProduct

export async function importconsumerProductValidation(orgId, data) {
  const aUrl = url + `organization/importConsumerProductValidation`;
  const config = {
    method: "post",
    url: aUrl,
    data: {
      organizationId: orgId,
      data: data,
    },
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}
export async function importconsumerProduct(orgId, data) {
  const aUrl = url + `organization/importConsumerProduct`;
  const config = {
    method: "post",
    url: aUrl,
    data: {
      organizationId: orgId,
      data: data,
    },
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

export async function fetchAllConsumerProduct(orgId, page, pageSize) {
  const aUrl =
    url +
    `organization/fetchConsumerProduct?organizationId=${orgId}&page=${page}&pageSize=${pageSize}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

// block user

export async function fetchBlockUserData(searchText, page, perPage) {
  const pages = page - 1;
  const aUrl =
    url +
    `system-admin/fetchBlockUserData?page=${pages}&pageSize=${perPage}&searchText=${
      searchText || ""
    }`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

export async function upsertGlobalProducerProductWebsiteLink(
  websiteLink,
  organizationId
) {
  const aUrl =
    url +
    `system-admin/upsertGlobalProducerProductWebsiteLink?websiteLink=${websiteLink}&organizationId=${organizationId}`;
  const config = {
    method: "put",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

export async function fetchHallsByEventId(eventId) {
  const aUrl = url + `system-admin/fetchHallsByEventId?eventId=${eventId}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

export async function createEventHall(data) {
  const aUrl = url + `system-admin/createEventHall`;
  const config = {
    method: "post",
    url: aUrl,
    data: data,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

export async function updateEventHall(data) {
  const aUrl = url + `system-admin/updateEventHall`;
  const config = {
    method: "post",
    url: aUrl,
    data: data,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

export async function deleteEventHall(hallId, boothDeletionFla) {
  const aUrl =
    url +
    `system-admin/deleteEventHall?hallId=${hallId}&boothDeletionFlag=${boothDeletionFla}`;
  const config = {
    method: "delete",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

export async function organizationLookupApiForBoothCreation(searchText) {
  const aUrl =
    url +
    `system-admin/organizationLookupApiForBoothCreation?searchText=${searchText}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

export async function createEventBooth(data) {
  const aUrl = url + `system-admin/createEventBooth`;
  const config = {
    method: "post",
    url: aUrl,
    data: data,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

export async function fetchBooths(eventId, filter) {
  const aUrl =
    url + `system-admin/fetchBooths?eventId=${eventId}&filter=${filter}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

export async function updateEventBooth(data) {
  const aUrl = url + `system-admin/updateEventBooth`;
  const config = {
    method: "post",
    url: aUrl,
    data: data,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

export async function deleteEventBooth(boothId) {
  const aUrl = url + `system-admin/deleteEventBooth?boothId=${boothId}`;
  const config = {
    method: "delete",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

// Newswire post data
export async function fetchNewsWirePost(
  searchText,
  page,
  perPage,
  filter,
  postType
) {
  const pages = page - 1;
  const aUrl =
    url +
    `system-admin/fetchNewsWirePost?page=${pages}&pageSize=${perPage}&postType=${postType}&filter=${filter}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

// delete post
export async function deleteNewsWirePostByAdmin(postId, postStatus) {
  const aUrl = url + `system-admin/deleteNewsWirePostByAdmin?postId=${postId}`;
  const config = {
    method: "put",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

// checking location excel sheet
export async function validationForLocationSheet(data, orgId) {
  const aUrl = url + `organization/importConsumerLocationValidation`;
  const config = {
    method: "put",
    url: aUrl,
    data: {
      data: data,
      organizationId: orgId,
    },
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

export async function fetchOrganizationPlans(pages, perPage,organizationId) {
  const page = pages - 1;
  const aUrl =
    url +
    `system-admin/organizationPlans?page=${page}&pageSize=${perPage}&organizationId=${organizationId}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

export async function setOrganizationPlan(data) {
  const aUrl = url + `system-admin/setOrganizationPlan`;
  const config = {
    method: "post",
    url: aUrl,
    data: data,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

export async function fetchOrganizationNewswirePosts(pages, perPage,organizationId) {
  const page = pages - 1;
  const aUrl =
    url +
    `system-admin/organizationNewswirePosts?page=${page}&pageSize=${perPage}&organizationId=${organizationId}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

export async function organizationNewswireRssFeeds(pages, perPage,organizationId) {
  const page = pages - 1;
  const aUrl =
    url +
    `system-admin/organizationNewswireRssFeeds?page=${page}&pageSize=${perPage}&organizationId=${organizationId}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

export async function getOrganizationPendingUsers(organizationId, pages, pageSize) {
  const page = pages - 1;
  const aUrl =
    url +
    `system-admin/fetchOrganizationsPendingUsers?organizationId=${organizationId}&page=${page}&pageSize=${pageSize}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

export async function fetchUserData(userId) {
  const aUrl =
    url +
    `system-admin/fetchUserData?userId=${userId}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: { 
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}


export async function deleteAccount(userId) {
  const aUrl =
    url +
    `system-admin/deleteAccount?userId=${userId}`;
  const config = {
    method: "put",
    url: aUrl,
    headers: { 
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

export async function activateAccount(userId) {
  const aUrl =
    url +
    `system-admin/activateAccount?userId=${userId}`;
  const config = {
    method: "put",
    url: aUrl,
    headers: { 
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

export async function hardDeleteUserByUserId(userId) {
  const aUrl =
    url +
    `system-admin/hardDeleteUserByUserId?userId=${userId}`;
  const config = {
    method: "put",
    url: aUrl,
    headers: { 
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

export async function updateOrganizationOwnership(userId) {
  const aUrl =
    url +
    `system-admin/updateOrganizationOwnership?userId=${userId}`;
  const config = {
    method: "put",
    url: aUrl,
    headers: { 
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

export async function deactivateOrganizationByAdmin(orgId) {
  const aUrl =
    url +
    `system-admin/deactivateOrganizationByAdmin?organizationId=${orgId}`;
  const config = {
    method: "put",
    url: aUrl,
    headers: { 
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

export async function activateOrganizationByAdmin(orgId) {
  const aUrl =
    url +
    `system-admin/activateOrganizationByAdmin?organizationId=${orgId}`;
  const config = {
    method: "put",
    url: aUrl,
    headers: { 
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

// createUpdateRSSFeedByAdmin

export async function createUpdateRSSFeedByAdmin(
 { id,
  name,
  url:feedUrl,
  organizationId,}
) {
  const aUrl = url + `newswire/createUpdateRSSFeedByAdmin`;
  const config = {
    method: "post",
    url: aUrl,
    data: {
      id:id || null,
      feedName:name,
      url:feedUrl,
      organizationId,
    },
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios(config);
}

export async function deleteRSSFeedByAdmin(rssFeedId) {
  const aUrl =
    url +
    `newswire/deleteRSSFeedByAdmin?rssFeedId=${rssFeedId}`;
  const config = {
    method: "put",
    url: aUrl,
    headers: { 
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

export async function getOrganizationEditData(orgId) {
  const aUrl = url + `system-admin/getOrganizationEditData?orgId=${orgId}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

export async function updateOrganizationData(data) {
  const aUrl = url + `system-admin/updateOrganizationData`;
  const config = {
    method: "put",
    url: aUrl,
    data:data,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

export async function addOrganizationNewKeyword(orgId,keyword) {
  const aUrl = url + `system-admin/addOrganizationNewKeyword?organizationId=${orgId}&keyword=${keyword}`;
  const config = {
    method: "put",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

export async function getOrganizationKeywordData(orgId) {
  const aUrl = url + `system-admin/getOrganizationKeywordData?orgId=${orgId}`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}

export async function deleteOrganizationKeyword(orgId,keywordId) {
  const aUrl = url + `system-admin/deleteOrganizationKeyword?orgId=${orgId}&keywordId=${keywordId}`;
  const config = {
    method: "delete",
    url: aUrl,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return await axios(config);
}


