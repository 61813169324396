import { Avatar, Box, Chip, ListItem, ListItemText, ListSubheader, Modal, Skeleton, SpeedDial, TextField, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import {
  DeleteErrorLogs,
  fetchOrganizationPlans,
  get_orgUsers,
  getErrorList,
  getProductList,
  getUnverifiedProducer,
  fetchUserData,
  deleteAccount,
  activateAccount,
  hardDeleteUserByUserId,
  updateOrganizationOwnership,
} from "api/api";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import { Link, useParams,useNavigate } from "react-router-dom";
import Loader from "ui-component/Loader";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { minHeight, minWidth } from "@mui/system";
import {    Stack, Divider, Grid } from '@mui/material';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InfoIcon from '@mui/icons-material/Info';
import { userActiveStatuses } from "utils/constant";

export default function OrganizationUsers() {
  const [columns, setColumns] = useState([]);
  const navigate = useNavigate()
  const [data, setData] = useState([]);
  const [loader, setLoader] = React.useState(false);
  const [pending, setPending] = React.useState(false);
  

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const theme = useTheme();
  const [searcgOption, setSearchOption] = useState([]);
  const [searcValueByuser, setSearchValueByuser] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [metaData, setMetaData] = useState({});

  const [userData,setUserData] = useState({});

  const [open, setOpen] = useState(false);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [dialogueData,setDialogueData] = useState({});

  const [actions,setActions] = useState({
    hardDeleteAccount:`hardDeleteAccount`,
    deactivateAccount:`deactivateAccount`,
    activateAccount:`activateAccount`,
    assignOrganizationOwnership:`assignOrganizationOwnership`,
  })

  const params = useParams()

  // Media query to check screen size, e.g., smaller screens (600px and below)
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const SearchHandle = async (e) => {
   
  };


  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const HandleApplyFilter = async () => {
    setPending(true);
    setLoader(true);
    await get_orgUsers(params.organizationId, page, perPage)
      .then((res) => {
        setData(res.data.data);
        setMetaData(res.data.metaData)
        setTotalRows(res.data.count);
      })
      .catch((err) => {});
    setPending(false);
    setLoader(false);
  };

  const fetchUserDetails = async (userId) => {
    setLoader(true);
    await fetchUserData(userId)
      .then((res) => {
        setUserData(res.data)
      })
      .catch((err) => {});
    setLoader(false);
  };

  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state?.selectedRows.map((r) => r.id));
  }, []);

  

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div>
  
      </div>
    );
  }, [SearchHandle, searcValueByuser]);


  const handleDateTime = (value) => {
    const date = moment.utc(value); // Parse the input as a UTC date
    const formattedDate = date.local().format("DD-MMM-YY hh:mm Z"); // Convert to local timezone and format
    return formattedDate;
}; 

const handleDateTimeForAccountHardDelete = (accountDeletedDate) => {
  const value = new Date(accountDeletedDate);
  const daysAfterDelete = 30;
  value.setDate(value.getDate() + daysAfterDelete);
  const date = moment.utc(value); // Parse the input as a UTC date
  const formattedDate = date.local().format("DD-MMM-YY hh:mm Z"); // Convert to local timezone and format
  return formattedDate;
};

function getTimeDifference(endDate) {
  // Convert endDate to a moment object
  const end = moment.utc(endDate);

  // Get the current date as a moment object
  const now = moment.utc();

  // Calculate the difference in years, months, and days
  const years = end.diff(now, 'years');
  const months = end.diff(now, 'months') % 12; // remainder after years
  const days = end.diff(now, 'days') % 30; // approximate number of days, assuming 30 days in a month

  // Construct a readable format
  let result = '';

  if (years > 0) {
    result += `${years} year${years > 1 ? 's' : ''} `;
  }

  if (months > 0) {
    result += `${months} month${months > 1 ? 's' : ''} `;
  }

  if (days > 0) {
    result += `${days} day${days > 1 ? 's' : ''} `;
  }

  // Trim the result to remove any trailing spaces
  result = result.trim();

  // If there's no difference, indicate that it's today or in the past
  if (!result) {
    result = '0 days';
  }

  return result;
}

  const ExpandedComponent = ({ data }) => (
    <div
      style={{
        width: "100%",
        padding: "20px 40px",
        display: "flex",
        flexDirection: "column",
        rowGap: "10px",
      }}
    >
      {
        data.planStatus==='Expired' ? <span>
        <strong>Expired status : </strong>
        <span>{data?.expiredStatus || 'Not available'}</span>
      </span>:<></>
      }
      {
        data.planStatus==='Active' ? <span>
        <strong>Plan Ends In:</strong>
        <span>{getTimeDifference(data.endDate)}</span>
      </span>:<></>
      }
      
      
    </div>
  );

  useEffect(() => {
    setColumns([
      {
        name: "Account Created At",
        selector: (row,index) => handleDateTime(row.createdAt),
        sortable: false,
        maxWidth: "200px",
      },
      {
        name: "User Name",
        selector: (row) =><div style={{display:'flex',justifyContent:'space-between',alignItems:'center',gap:'10px'}}><Avatar alt={row.name} src={row.profileImage} /> {row.name}</div> ,
        sortable: true,
        maxWidth: "600px",
      },
      {
        name: "Email",
        selector: (row) => row.email,
        sortable: true,
        maxWidth: "600px",
      },
      {
        name: "Role",
        selector: (row) => row.role,
        sortable: true,
        maxWidth: "600px",
      },
      {
        name: "Status",
        selector: (row) => row.active ? 'Active':'De Active' ,
        sortable: true,
        cell: row => (
          <span style={{ color: row.active ? 'green' : 'red',fontWeight:'bold' }}>
            {row.active ? 'Active' : 'De Active'}
          </span>
        ),
        maxWidth: "600px",
      },
      {
        name: "Check in Detail",
        selector: (row) =><ListItem sx={{padding:'0'}} >
            <Button variant="outlined" onClick={() => handleOpen(row)} >Details</Button>
          </ListItem> ,
        sortable: true,
        maxWidth: "600px",
      },
    ]);
  }, []);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleBack = () => {
    navigate(`/dashboard/organization-setting/${params.organizationId}`);
  };
  


  const handleOpen = row => {
    setOpen(true);
    fetchUserDetails(row.id)
  };

  const handleClose = () => {
    setOpen(false);
    HandleApplyFilter()
  };

  const handleOpenDialogue = (userData,action) => {
    switch(action){
      case actions.activateAccount: 
        setDialogueData({
          expectedLabel: userData.createdOrg ? `Activate organization ${userData.organizationName}` : `Activate user ${userData.firstName} ${userData.lastName}`,
          action,
          showAlert:false,
          severity:'error',
          alertText:``,
          userId:userData.id,
          dialogueStatus:dialogueStatuses.activateAccount
        });
      break;
      case actions.assignOrganizationOwnership: 
        setDialogueData({
          expectedLabel: `Assign ownership to ${userData.firstName} ${userData.lastName}`,
          action,
          showAlert:false,
          severity:'error',
          alertText:``,
          userId:userData.id,
          dialogueStatus:dialogueStatuses.assignOrganizationOwnership,
        });
      break;
      case actions.deactivateAccount: 
      setDialogueData({
        expectedLabel: userData.createdOrg ? `Deactivate organization ${userData.organizationName}` : `Deactivate user ${userData.firstName} ${userData.lastName}`,
        action,
        showAlert:false,
        severity:'error',
        alertText:``,
        userId:userData.id,
        dialogueStatus:dialogueStatuses.deactivateAccount
      });
      break;
      case actions.hardDeleteAccount: 
        setDialogueData({
          expectedLabel: userData.createdOrg ? `Hard Delete organization ${userData.organizationName}` : `Hard Delete user ${userData.firstName} ${userData.lastName}`,
          action,
          showAlert:true,
          severity:'error',
          alertText:`Caution: This action is final and cannot be undone.`,
          userId:userData.id,
          dialogueStatus:dialogueStatuses.hardDeleteAccount,
        });
      break;
      default: break;
    }
    setOpenDialogue(true);
    
  };

  const handleCloseDialogue = () => {
    setOpenDialogue(false)
    setDialogueData({})
  };

  const accountDeletedType = {
    selfDeleted: 'selfDeleted',
    systemDeleted: 'systemDeleted', // Refer to VTC ADMIN
    organizationDeleted: 'organizationDeleted', // Refer to Organization Level
    default: 'default', // Not-Specified
  };

  const dialogueStatuses = {
    deactivateAccount:'deactivateAccount',
    activateAccount:'activateAccount',
    hardDeleteAccount:'hardDeleteAccount',
    assignOrganizationOwnership:'assignOrganizationOwnership'
  }

  // Modal Styles
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isSmallScreen ? '90%' : isMediumScreen ? '80%': isLargeScreen ? '70%':'70%',
    maxHeight: '80vh', // Prevent overflow on small screens
    minHeight:'80vh',
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 24,
    overflowY: 'auto', // Add scrolling for overflow content
    p: 4,

  };

  useEffect(() => {
    HandleApplyFilter();
  }, [page, perPage]);

  return (
    <>
      {loader === true && <Loader />}
      <DataTable
        title={<div>
           <ListSubheader
            style={{
              fontSize: "20px",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              width: "100px",
            }}
            onClick={() => handleBack()}
          >
            {/* <Link to={"/dashboard/user-list"}> */}
            <KeyboardBackspaceIcon /> Back
            {/* </Link> */}
          </ListSubheader>
            <div style={{display:'flex',justifyContent:'flex-start',gap:'10px'}}>
              <div style={{display:'flex',justifyContent:'flex-start',gap:'10px'}}>
                <p>Organization's Users   </p>
                <p>- {metaData?.organizationName || 'No name'} ({metaData.userCount}) </p>
              </div>
              <div style={{display:'flex',justifyContent:'center',alignItems:'center',gap:'10px'}}>
              <Avatar src={metaData.organizationProfileImage} ></Avatar>
              </div>
            </div>
        </div>}
        columns={columns}
        data={data}
        progressPending={pending}
        pagination
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        persistTableHead
        fixedHeader
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        highlightOnHover
        // selectableRows
        // contextActions={contextActions}
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleCleared}
        // row exapndable
        expandableRows={false}
        expandableRowsComponent={ExpandedComponent}
        expandOnRowClicked={false}
        expandOnRowDoubleClicked={false}
        expandableRowsHideExpander={false}
      />

      {/* Modal for displaying selected row details */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          {
            loader ? <>
                <Box sx={{display:'flex',justifyContent:'space-between'}}>
            <Stack direction="row" alignItems="center" spacing={2} mb={2}>
               <Skeleton variant="circular" width={60} height={60} />
               <Typography variant="h1">{loader ? <Skeleton width={200} /> : 'h1'}</Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={2} mb={2}>
              <Skeleton variant="circular" width={60} height={60} />
              <Typography variant="h1">{loader ? <Skeleton width={200} /> : 'h1'}</Typography>
              </Stack>
            </Box>
            <Skeleton variant="rectangular" animation={'wave'} width={'100%'} height={60} />
            <Skeleton variant="rectangular" animation={'wave'} width={'100%'} height={60} />
            <Skeleton variant="rectangular" animation={'wave'} width={'100%'} height={60} />
            <Skeleton variant="rectangular" animation={'wave'} width={'100%'} height={60} />
            <Skeleton variant="rectangular" animation={'wave'} width={'100%'} height={60} />
            <Skeleton variant="rectangular" animation={'wave'} width={'100%'} height={60} />
            <Skeleton variant="rectangular" animation={'wave'} width={'100%'} height={60} />
            </>: <Box>    
            <Box sx={{display:'flex',justifyContent:'space-between'}}>
            <Stack direction="row" alignItems="center" spacing={2} mb={2}>
                <Avatar
                  src={userData.profileImage}
                  alt={`${userData.firstName} ${userData.lastName}`}
                  sx={{ width: 60, height: 60 }}
                />
                <Typography variant="h3">
                  {userData.firstName} {userData.lastName} 
                  
                </Typography>
                {
                    userData.active ? <Box
                    sx={{
                      width: 10,
                      height: 10,
                      backgroundColor: 'green', // Color for active status
                      borderRadius: '50%', // Makes the box a circle
                      display: 'inline-block',
                    }}
                  />:<></>
                  }    
                  {
                    userData.emailVerified ? <></>: <><Chip size="medium" label="Unconfirmed Account" color="error"></Chip></>
                  }
                  {
                    userData.accountDeleted ? <><Chip size="medium" label="Account Deleted" color="error"></Chip></>: <></>
                  }
                  {/* {
                    userData.active ? <><Chip size="medium" label="Active" color="success"></Chip></>: <></>
                  } */}

                 
                  {
                    userData.createdOrg ? <><Chip size="medium" label="Owner" color="warning"></Chip></>: <></>
                  }
                  {
                    !userData.createdOrg ? <><Chip size="medium" label="Member" color="secondary"></Chip></>: <></>
                  }
                  
                 
              </Stack>
              <Stack direction="row" alignItems="center" spacing={2} mb={2}>
                <Avatar
                  src={userData.organizationProfileImage}
                  alt={`${userData.organizationName}`}
                  sx={{ width: 60, height: 60 }}
                />
                <Typography variant="h3">
                  {userData.organizationName} 
                </Typography>
                  {
                    userData.organizationActive ? <Box
                    sx={{
                      width: 10,
                      height: 10,
                      backgroundColor: 'green', // Color for active status
                      borderRadius: '50%', // Makes the box a circle
                      display: 'inline-block',
                    }}
                  />:<></>
                  }  
                  {
                    userData.organizationDeleted ? <><Chip size="medium" label="Deactivated" color="error"></Chip></>: <></>
                  }
              </Stack>
            </Box>
            <Divider textAlign="left"  sx={{ mb: 2 }} >General User Detail</Divider>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} >
                <Typography variant="subtitle1" color="text.secondary">
                  Email:
                </Typography>
                <Typography sx={{paddingLeft:'4px',fontWeight:'bold',fontSize:'16px'}}>{userData.email}</Typography>
              </Grid> 
              <Grid item xs={12} sm={6} md={4} >
                <Typography variant="subtitle1" color="text.secondary">
                  Mobile:
                </Typography>
                <Typography sx={{paddingLeft:'4px',fontWeight:'bold',fontSize:'16px'}}>{userData.mobileNumber}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} >
                <Typography variant="subtitle1" color="text.secondary">
                  Account Created at:
                </Typography>
                <Typography sx={{paddingLeft:'4px',fontWeight:'bold',fontSize:'16px'}}>{handleDateTime(userData.createdAt)}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} >
                <Typography variant="subtitle1" color="text.secondary">
                  Role:
                </Typography>
                <Typography sx={{paddingLeft:'4px',fontWeight:'bold',fontSize:'16px'}}>{userData.role}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} >
                <Typography variant="subtitle1" color="text.secondary">
                  Active:
                </Typography>
                <Typography sx={{paddingLeft:'4px',fontWeight:'bold',fontSize:'16px'}} color={userData.active ? 'green' : '#FF7F50'}>
                  {userData.active ? 'Yes' : 'No'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} >
                <Typography variant="subtitle1" color="text.secondary">
                  Time Zone:
                </Typography>
                <Typography sx={{paddingLeft:'4px',fontWeight:'bold',fontSize:'16px'}}>{userData.timeZoneDisplayName}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} >
                <Typography variant="subtitle1" color="text.secondary">
                  Joining Type:
                </Typography>
                <Typography sx={{paddingLeft:'4px',fontWeight:'bold',fontSize:'16px'}}>{userData.joiningType || 'Not Available'}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} >
                <Typography variant="subtitle1" color="text.secondary">
                  Organization's Owner:
                </Typography>
                <Typography sx={{paddingLeft:'4px',fontWeight:'bold',fontSize:'16px'}}>{userData.createdOrg ? 'Yes':'No'}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} >
                <Typography variant="subtitle1" color="text.secondary">
                  About:
                </Typography>
                <Typography sx={{paddingLeft:'4px',fontWeight:'bold',fontSize:'16px'}}>{userData.about ? userData.about:'Not Specified' }</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} >
                <Typography variant="subtitle1" color="text.secondary">
                  Job Title:
                </Typography>
                <Typography sx={{paddingLeft:'4px',fontWeight:'bold',fontSize:'16px'}}>{userData.jobTitle || 'Not Specified'}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} >
                <Typography variant="subtitle1" color="text.secondary">
                      Email Verified:  
                </Typography>
                <Typography sx={{paddingLeft:'4px',color:userData.emailVerified ? 'green':'red',fontWeight:'bold',fontSize:'16px'}}>{userData.emailVerified ? 'Yes':'No' || 'Not Specified'}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} >
                <Typography variant="subtitle1" color="text.secondary">
                     Organization Imported/Created by Admin:  
                </Typography>
                <Typography sx={{paddingLeft:'4px',fontWeight:'bold',fontSize:'16px'}}>{userData.createdBySystemAdmin ? 'Yes':'No' || 'Not Specified'}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} >
                <Typography variant="subtitle1" color="text.secondary">
                     Self Registered Organization:  
                </Typography>
                <Typography sx={{paddingLeft:'4px',fontWeight:'bold',fontSize:'16px'}}>{userData.createdBySystemAdmin ? 'No':'Yes' || 'Not Specified'}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} >
                <Typography variant="subtitle1" color="text.secondary">
                     Default Contact Type:  
                </Typography>
                <Typography sx={{paddingLeft:'4px',fontWeight:'bold',fontSize:'16px'}}>
                  {
                    userData.organizationPersonList?.length>0 ? <>
                    <Stack direction={'row'} spacing={1}>
                  {
                    userData.organizationPersonList?.map((e)=>{
                      return <Chip size="medium" label={e} color="secondary"></Chip>
                    })
                  }
                  </Stack></>:<>
                    Not Specified
                  </>
                  }
                  
                </Typography>
              </Grid>
              {
                userData.emailVerified ? <> {
                  userData.active ? <></>:  <Grid item xs={12} sm={6} md={4} >
                  <Typography variant="subtitle1" color="text.secondary">
                    Account Deleted Staus:
                  </Typography>
                  <Typography sx={{paddingLeft:'4px',color:'#FF7F50',fontWeight:'bold',fontSize:'16px'}}>{userData.accountDeleted ? userData.accountDeletedStatus:'' || 'Not Specified'}</Typography>
                </Grid>
                }
                {
                  userData.accountDeleted ? <Grid item xs={12} sm={6} md={4} >
                  <Typography variant="subtitle1" color="text.secondary">
                    Account Deleted Date:
                  </Typography>
                  <Typography sx={{paddingLeft:'4px',fontWeight:'bold',fontSize:'16px'}}>{ handleDateTime(userData.accountDeletedDate) || 'Not Specified'}</Typography>
                </Grid>:<></>  
                }</>:<></>
              }
              </Grid>

              <Divider textAlign="left"  sx={{ mb: 2,mt:2 }} >User Counters</Divider>
              <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} >
                <Typography variant="subtitle1" color="text.secondary">
                     Created Event:  
                </Typography>
                <Typography sx={{paddingLeft:'4px',fontWeight:'bold',fontSize:'16px'}}>{userData.eventCreatedCount === '0' ? 'N/A' : userData.eventCreatedCount}</Typography>
              </Grid>
              {
                userData.organizationRole==='Consumer' ? <>
                 <Grid item xs={12} sm={6} md={4} >
                <Typography variant="subtitle1" color="text.secondary">
                     Roster Product:  
                </Typography>
                <Typography sx={{paddingLeft:'4px',fontWeight:'bold',fontSize:'16px'}}>{userData.rosterProductCounter==='0' ? 'N/A':userData.rosterProductCounter}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} >
                <Typography variant="subtitle1" color="text.secondary">
                     Roster Location:  
                </Typography>
                <Typography sx={{paddingLeft:'4px',fontWeight:'bold',fontSize:'16px'}}>{userData.rosterLocationCounter===
                  '0'? 'N/A':userData.rosterLocationCounter}</Typography>
              </Grid>
              </>:<>
              <Grid item xs={12} sm={6} md={4} >
                <Typography variant="subtitle1" color="text.secondary">
                     Product:  
                </Typography>
                <Typography sx={{paddingLeft:'4px',fontWeight:'bold',fontSize:'16px'}}>{userData.productCounter === '0' ? 'N/A' : userData.productCounter}</Typography>
              </Grid></>
              }
              <Grid item xs={12} sm={6} md={4} >
                <Typography variant="subtitle1" color="text.secondary">
                     Event Comment:  
                </Typography>
                <Typography sx={{paddingLeft:'4px',fontWeight:'bold',fontSize:'16px'}}>{userData.eventCommentCounter === '0' ? 'N/A' : userData.eventCommentCounter
                }</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} >
                <Typography variant="subtitle1" color="text.secondary">
                     Event Action:  
                </Typography>
                <Typography sx={{paddingLeft:'4px',fontWeight:'bold',fontSize:'16px'}}>{userData.eventActionCounter === '0' ? 'N/A' : userData.eventActionCounter
                }</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} >
                <Typography variant="subtitle1" color="text.secondary">
                     Event Reaction:  
                </Typography>
                <Typography sx={{paddingLeft:'4px',fontWeight:'bold',fontSize:'16px'}}>{userData.eventReactionCounter === '0' ? 'N/A' : userData.eventReactionCounter
                }</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} >
                <Typography variant="subtitle1" color="text.secondary">
                     Event Booth:  
                </Typography>
                <Typography sx={{paddingLeft:'4px',fontWeight:'bold',fontSize:'16px'}}>{userData.boothCounter === '0' ? 'N/A' : userData.boothCounter
                }</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} >
                <Typography variant="subtitle1" color="text.secondary">
                     Newswire Post:  
                </Typography>
                <Typography sx={{paddingLeft:'4px',fontWeight:'bold',fontSize:'16px'}}>{userData.newswirePostCounter === '0' ? 'N/A' : userData.newswirePostCounter
                }</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} >
                <Typography variant="subtitle1" color="text.secondary">
                     RSS Feed:  
                </Typography>
                <Typography sx={{paddingLeft:'4px',fontWeight:'bold',fontSize:'16px'}}>{userData.rssFeedCounter === '0' ? 'N/A' : userData.rssFeedCounter
                }</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} >
                <Typography variant="subtitle1" color="text.secondary">
                     Pin Post:  
                </Typography>
                <Typography sx={{paddingLeft:'4px',fontWeight:'bold',fontSize:'16px'}}>{userData.pinPostCounter === '0' ? 'N/A' : userData.pinPostCounter
                }</Typography>
              </Grid>

              

              
             
            </Grid>

            <Divider textAlign="left" sx={{ mt: 2, mb: 2 }} >Operations on User </Divider>
            <Grid container spacing={2}>
              {
                (userData.accountDeleted && userData.accountDeletedType===accountDeletedType.systemDeleted) || (!userData.emailVerified) ? <>
                {
                  userData.createdOrg ? <>
                  <Grid item  xs={12} sm={6} md={4}>
                <Button onClick={()=>{handleOpenDialogue(userData,actions.hardDeleteAccount)}} fullWidth={true} variant="outlined" color="error">Hard Delete organization {userData.organizationName}</Button>
            </Grid></>:<><Grid item  xs={12} sm={6} md={4}>
                <Button onClick={()=>{handleOpenDialogue(userData,actions.hardDeleteAccount)}} fullWidth={true} variant="outlined" color="error">Hard Delete user {`${userData.firstName} ${userData.lastName}`} </Button>
            </Grid></>
                }
                </>:<></>
              }

              {
                userData.emailVerified && !userData.createdOrg && userData.active ? <><Grid item  xs={12} sm={6} md={4}>
                <Button onClick={()=>{handleOpenDialogue(userData,actions.assignOrganizationOwnership)}} fullWidth={true} variant="outlined" color="secondary">Assign organization ownership to {`${userData.firstName} ${userData.lastName}`} </Button>
            </Grid></>:<></>
              }

              {
                userData.emailVerified ? <>
                {
                userData.active ? <>{
                  (userData.createdOrg && userData.activeUserCounterExceptCreator>0) ? <Grid item  xs={12} sm={6} md={4}>
                  <Button onClick={()=>{handleOpenDialogue(userData,actions.deactivateAccount)}} fullWidth={true} variant="outlined" disabled={true} color="secondary">Deactivate user {`${userData.firstName} ${userData.lastName}`} </Button>
                  
              </Grid>:<Grid item  xs={12} sm={6} md={4}>
                  <Button onClick={()=>{handleOpenDialogue(userData,actions.deactivateAccount)}} fullWidth={true} variant="outlined" color="secondary">Deactivate user {`${userData.firstName} ${userData.lastName}`} </Button>
              </Grid>
                }</>:<>
                  {
                  userData.createdOrg ? <Grid item  xs={12} sm={6} md={4}>
                  <Button 
                    disabled={((userData.accountDeleted && userData.
                      accountDeletedType===accountDeletedType.selfDeleted) || (userData.userActiveStatus===userActiveStatuses.ORGANIZATION_DEACTIVATED_BY_ADMIN)) } 
                    
                    onClick={()=>{handleOpenDialogue(userData,actions.activateAccount)}} fullWidth={true} variant="outlined" color="info">Activate organization {userData.organizationName}</Button>
              </Grid>:<Grid item  xs={12} sm={6} md={4}>
                  <Button 
                    disabled={(userData.accountDeleted && userData.
                    accountDeletedType===accountDeletedType.selfDeleted) || (userData.organizationDeleted) || (userData.userActiveStatus===userActiveStatuses.ORGANIZATION_DEACTIVATED_BY_ADMIN) } 
                    
                    onClick={()=>{handleOpenDialogue(userData,actions.activateAccount)}} fullWidth={true} variant="outlined" color="info">Activate User {`${userData.firstName} ${userData.lastName}`}</Button>
              </Grid>
                }
                </>
              }</>:<>
                
              </>
              }
              
              
            </Grid>

            <Divider textAlign="left" sx={{ mt: 2, mb: 2 }} >Alerts</Divider>
            <Stack sx={{ width: '100%',mt:'10px' }} spacing={2}>
              {/* <Alert variant="standard" severity="info">
                This account is scheduled for permananent deletion by the user self.
              </Alert> */}
              {
                userData.emailVerified ? <></>:<> <Alert sx={{fontSize:'16px'}}  variant="standard" severity="error">
                This account's email has not been verified yet. The user remains unconfirmed!
              </Alert></>
              }
              {
                (userData.createdOrg && userData.activeUserCounterExceptCreator>0) ? <> <Alert sx={{fontSize:'16px'}}  variant="standard" severity="error">
                If you want to deactivate this account, please assign ownership to another user first.
              </Alert></>:<></>
              }
              {
                (userData.emailVerified && userData.createdOrg && userData.activeUserCounterExceptCreator<=0 && !userData.accountDeleted
                ) ? <> <Alert sx={{fontSize:'16px'}}  variant="standard" severity="error">
                 Deactivating this account will result in the deactivating of the entire organization since there are no other users left. Proceed with caution.
              </Alert></>:<></>
              }
              {
                userData.accountDeleted && userData.
                accountDeletedType===accountDeletedType.selfDeleted ? <><Alert sx={{fontSize:'16px'}}  variant="standard" severity="error">
               This account can only be activated by {`${userData.firstName} ${userData.lastName}`}!
              </Alert></>:<> </>
              } 
              {
                userData.accountDeleted && userData.accountDeletedType===accountDeletedType.selfDeleted ? <><Alert sx={{fontSize:'16px'}}  variant="standard" severity="error">
                This account is scheduled for permanent deletion after {handleDateTimeForAccountHardDelete(userData.accountDeletedDate)}! Please ensure all necessary actions are completed before this date and time.
               </Alert></>:<></>
              }
              {
                userData.accountDeleted && userData.accountDeletedType===accountDeletedType.systemDeleted && !userData.organizationDeleted ? <><Alert sx={{fontSize:'16px'}}  variant="standard" severity="error">
                This account has been deactivated by the VTC Admin. Only the VTC Admin has the authority to reactivate it.</Alert></>:<></>
              }
              {/* {
                userData.organizationDeleted ? <><Alert sx={{fontSize:'16px'}}  variant="standard" severity="error">
                This user's organization is currently inactive.!</Alert></>:<></>
              } */}
              {
                userData.accountDeleted && userData.accountDeletedType===accountDeletedType.systemDeleted ? <><Alert sx={{fontSize:'16px'}}  variant="standard" severity="error">
                Note: This account will not be permanently deleted automatically. Only the VTC Admin has the authority to perform a hard deletion.</Alert></>:<></>
              }
             
            </Stack>
          </Box>
          }
         

        </Box>
      </Modal>  
        
      <Dialog
        open={openDialogue}
        onClose={handleCloseDialogue}
        sx={{width:'800px',margin:'auto'}}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const confirmationTextByUser = formJson.confirmationText;
            const expectedConfirmationText = dialogueData.expectedLabel
            if(expectedConfirmationText===confirmationTextByUser){

              // toast.success(`Text is valid`, {
              //   position: "top-right",
              //   autoClose: 3000,
              //   hideProgressBar: false,
              //   closeOnClick: true,
              //   pauseOnHover: true,
              //   draggable: true,
              //   progress: undefined,
              //   theme: "colored",
              // });

              // if( dialogueStatus:'Deactivate')
              if(dialogueData.dialogueStatus===dialogueStatuses.deactivateAccount){
                // API Call to delete account 
                const deleteAccountAPICall = async () => {
                  setLoader(true);
                  await deleteAccount(dialogueData.userId)
                    .then((res) => {
                      if(res.data && res.data.status==='success'){
                        toast.success(`Deactivated successfully!`, {
                          position: "top-right",
                          autoClose: 3000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "colored",
                        });
                        // this is just for refreshing the model
                        
                        fetchUserDetails(dialogueData.userId)
                      }else{
                        toast.error(`Not able to deactive account!`, {
                          position: "top-right",
                          autoClose: 3000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "colored",
                        });
                      }
                    })
                    .catch((err) => {
                      toast.error(`Not able to deactive account!`, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                      });
                    });
                  // setLoader(false);
                };

                deleteAccountAPICall().then(()=>{}).catch(()=>{}).finally(()=>{
                  handleCloseDialogue(); 
                })
              }

              if(dialogueData.dialogueStatus===dialogueStatuses.activateAccount){
                // API Call to activate account 
                const activateAccountAPICall = async () => {
                  setLoader(true);
                  await activateAccount(dialogueData.userId)
                    .then((res) => {
                      if(res.data && res.data.status==='success'){
                        toast.success(`Activated successfully!`, {
                          position: "top-right",
                          autoClose: 3000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "colored",
                        });

                        // this is just for refreshing the model
                        fetchUserDetails(dialogueData.userId)

                      }else{
                        toast.error(`Not able to acctivate account!`, {
                          position: "top-right",
                          autoClose: 3000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "colored",
                        });
                      // this is just for refreshing the model
                      fetchUserDetails(dialogueData.userId)
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                      
                      toast.error(`${err?.response?.data?.message?.split(':')[1] || 'Error!'}`, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                      });
                      // this is just for refreshing the model
                      fetchUserDetails(dialogueData.userId)
                    });
                  // setLoader(false);
                };

                activateAccountAPICall().then(()=>{}).catch(()=>{}).finally(()=>{
                  handleCloseDialogue(); 
                })
              }

              if(dialogueData.dialogueStatus===dialogueStatuses.hardDeleteAccount){
                // API Call to activate account 
                const hardDeleteAccountAPICall = async () => {
                  setLoader(true);
                  await hardDeleteUserByUserId(dialogueData.userId)
                    .then((res) => {
                      if(res.data && res.data.status==='success'){
                        toast.success(`Hard Deleted successfully!`, {
                          position: "top-right",
                          autoClose: 3000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "colored",
                        });

                        // Redirecting
 
                        if(userData.createdOrg){
                          navigate('/dashboard/organization-list')
                        }else{
                          handleClose()
                          navigate(`/dashboard/organization/users/${userData.organizationId}`)
                        }

                      }else{
                        toast.error(`Not able to hard delete account!`, {
                          position: "top-right",
                          autoClose: 3000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "colored",
                        });
                      }
                    })
                    .catch((err) => {
                      toast.error(`Not able to hard delete account!`, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                      });
                    });
                  // setLoader(false);
                };

                hardDeleteAccountAPICall().then(()=>{}).catch(()=>{}).finally(()=>{
                  handleCloseDialogue(); 
                })
              }

              if(dialogueData.dialogueStatus===dialogueStatuses.assignOrganizationOwnership){
                  // API Call to activate account 
                const updateOrganizationOwnershipAPICall = async () => {
                  setLoader(true);
                  await updateOrganizationOwnership(dialogueData.userId)
                    .then((res) => {
                      if(res.data && res.data.status==='success'){
                        toast.success(`Assigned ownership successfully!`, {
                          position: "top-right",
                          autoClose: 3000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "colored",
                        });

                        // this is just for refreshing the model
                        fetchUserDetails(dialogueData.userId)

                      }else{
                        toast.error(`Not able to update organization ownership!`, {
                          position: "top-right",
                          autoClose: 3000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "colored",
                        });
                      }
                    })
                    .catch((err) => {
                      toast.error(`Not able to update organization ownership!`, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                      });
                    });
                  // setLoader(false);
                };

                updateOrganizationOwnershipAPICall().then(()=>{}).catch(()=>{}).finally(()=>{
                  handleCloseDialogue(); 
                })
              }

       

            }else{
              toast.error(`Hmm, Wrong text`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
          },
        }}
      > 

        {
          loader ? <></>:<>
           {
            dialogueData.showAlert ? <Alert sx={{fontSize:'20px'}} severity={dialogueData.severity} >{dialogueData.alertText}</Alert>:<></>
        } 
        <DialogTitle fontSize={'20px'}> {dialogueData.expectedLabel} </DialogTitle>
        <DialogContent>
          <DialogContentText fontSize={'15px'}>
          To verify, type  <span style={{color:'red'}}>{dialogueData.expectedLabel}</span> below:
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="confirmationText" 
            label=""
            type="text"
            fullWidth
            variant="standard"
          />
        
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogue}>Cancel</Button>
          <Button type="submit">{loader ? 'Validating...':'Submit'}</Button>
        </DialogActions>
          </>
        }

       
      </Dialog>
    </>
  );
}

